import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#5ABAB2",
        secondary: "#F5B63F",
        accent: "#EE6F2D",
        error: "#CB474B",
        info: "#F5B63F",
        success: "#5ABAB2",
        warning: "#CB474B",
        backdrop: "#2A2F3A",
      },
    },
  },
});

Vue.use(Vuetify);

export default vuetify;
