import axios from "axios";
import router from "@/router";

const state = {
  username: null,
  firstName: null,
  lastName: null,
  token: null,
  user_id: null,
  storage_accepted: null,
  refresh_token: null,
  username_live: null,
  user_id_live: null,
  token_live: null,
  refresh_token_live: null,
  globalError: { error: "", typeofalert: "" },
  login_refresh_route: null,
  config: null,
};

const mutations = {
  login_refresh_route(state, current_route_and_config) {
    state.login_refresh_route = current_route_and_config[0];
    state.config = current_route_and_config[1];
  },
  clear_login_refresh_route(state) {
    state.login_refresh_route = null;
    state.config = null;
  },
  authUser(state, userData) {
    state.username = userData.username;
    state.firstName = userData.firstName;
    state.lastName = userData.lastName;
    state.token = userData.token;
    state.refresh_token = userData.refresh_token;
    state.user_id = userData.user_id;
    state.globalError = { error: "", typeofalert: "" };
  },
  change_profile_data(state, profile_data) {
    state.firstName = profile_data.firstName;
    state.lastName = profile_data.lastName;
    state.username = profile_data.email;
  },
  authUser_live(state, userData) {
    state.username_live = userData.username_live;
    state.token_live = userData.token_live;
    state.refresh_token_live = userData.refresh_token_live;
    state.storage_accepted = userData.storage_accepted;
    state.user_id_live = userData.user_id_live;
  },
  save_storage_accepted_state(state, accepted_status) {
    state.storage_accepted = accepted_status;
  },
  clearAuthData(state) {
    state.username = null;
    state.firstName = null;
    state.lastName = null;
    state.token = null;
    state.refresh_token = null;
    state.user_id = null;
    state.globalError = { error: "", typeofalert: "" };
  },
  clearGlobalError(state) {
    state.globalError = { error: "", typeofalert: "" };
  },
  clearAuthData_live(state) {
    state.username_live = null;
    state.token_live = null;
    state.refresh_token_live = null;
    state.user_id_live = null;
  },
  setGlobalError(state, error) {
    state.username = null;
    state.firstName = null;
    state.lastName = null;
    state.token = null;
    state.refresh_token = null;
    state.user_id = null;
    state.globalError = { error: error.error, typeofalert: error.typeofalert };
  },
};

const getters = {
  isAuthenticated(state) {
    return state.token !== null;
  },
  getTokenLive(state) {
    return state.token_live;
  },
  getUserName(state) {
    return state.username;
  },
  getGlobalError(state) {
    return state.globalError;
  },
  getUserId(state) {
    return state.user_id;
  },
};

const actions = {
  change_profile_data: ({ commit }, profile_data) => {
    commit("change_profile_data", profile_data);
    localStorage.setItem("username", profile_data.email);
    localStorage.setItem("firstName", profile_data.firstName);
    localStorage.setItem("lastName", profile_data.lastName);
  },

  login: ({ commit }, authData) => {
    return axios
      .post(
        "/api/login",
        {
          username: authData.username,
          password: authData.password,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        let success = response.data.success;
        if (success === true) {
          commit("authUser", {
            username: authData.username,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            token: response.data.token,
            refresh_token: response.data.refresh_token,
            user_id: response.data.user_id,
          });
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("username", authData.username);
          localStorage.setItem("firstName", response.data.firstName);
          localStorage.setItem("lastName", response.data.lastName);
          localStorage.setItem("user_id", response.data.user_id);
          router.replace("/dashboard");
        } else {
          commit("setGlobalError", {
            error: "Invalid credentials",
            typeofalert: "warning",
          });
        }
      })
      .catch((error) => {
        if (error.response == undefined) {
          commit("setGlobalError", {
            error: "Fehler.",
            typeofalert: "warning",
          });
        } else if (error.response.status === 418) {
          commit("setGlobalError", {
            error:
              "Ihr Passwort ist abgelaufen. Bitte setzen Sie Ihr Passwort zurück.",
            typeofalert: "warning",
          });
        } else if (error.response.status == 429) {
          commit("setGlobalError", {
            error: "Zu viele Versuche. Bitte versuchen Sie es später erneut.",
            typeofalert: "warning",
          });
        } else {
          commit("setGlobalError", {
            error: "Invalid credentials",
            typeofalert: "warning",
          });
        }
        throw error;
      });
  },

  clear_login_refresh_route: ({ commit }) => {
    commit("clear_login_refresh_route");
  },

  register_magic_link: ({ commit }, authData) => {
    axios
      .post("/api/magic_link_registration", {
        username: authData.username,
        firstName: null,
        lastName: null,
        password: authData.password,
        magic_link: authData.magic_link,
      })
      .then((response) => {
        let success = response.data.success;

        if (success === true) {
          commit("authUser", {
            username: authData.username,
            token: response.data.token,
            refresh_token: response.data.refresh_token,
            user_id: response.data.user_id,
          });
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("username", authData.username);
          localStorage.setItem("firstName", authData.firstName);
          localStorage.setItem("lastName", authData.lastName);
          localStorage.setItem("user_id", response.data.user_id);
          router.replace("/dashboard");
        } else {
          commit("setGlobalError", {
            error: response.data.msg,
            typeofalert: "warning",
          });
        }
      })
      .catch((error) => {
        if (error.response.status != 400) {
          commit("setGlobalError", {
            error: error.response.statusText,
            typeofalert: "warning",
          });
        } else {
          commit("setGlobalError", {
            error: error.response.data.msg,
            typeofalert: "warning",
          });
        }
      });
  },

  // auto login registered users upon load
  autoLogin({ commit }) {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");
    let refresh_token = localStorage.getItem("refresh_token");
    let user_id = localStorage.getItem("user_id");

    if (!token || !username || token == "null" || token == null) {
      return;
    }
    commit("authUser", {
      username: username,
      firstName: firstName,
      lastName: lastName,
      token: token,
      refresh_token: refresh_token,
      user_id: user_id,
    });
  },

  // auto populate store for known live users
  autoLoginLive(context) {
    context.commit("authUser_live", {
      username_live: localStorage.getItem("username_live"),
      token_live: localStorage.getItem("token_live"),
      refresh_token_live: localStorage.getItem("refresh_token_live"),
      user_id_live: localStorage.getItem("user_id_live"),
      storage_accepted: true,
    });
  },

  logout: ({ commit }) => {
    commit("clearAuthData");
    localStorage.removeItem("username");
    localStorage.removeItem("lastName");
    localStorage.removeItem("firstName");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_id");

    localStorage.setItem("refresh_token", null);
    localStorage.setItem("token_live", null);
    localStorage.setItem("username_live", null);
    localStorage.setItem("user_id_live", null);
    localStorage.setItem("history", null);

    commit("setGlobalError", {
      error: "Sie wurden abgemeldet. Bitte erneut anmelden.",
      typeofalert: "warning",
    });

    router.replace({ path: "/login" });
  },

  delete_privacy: () => {
    localStorage.setItem("token_live", null);
  },

  login_refresh: ({ commit }, current_route_and_config) => {
    commit("login_refresh_route", current_route_and_config);
    localStorage.setItem("login_refresh_route", current_route_and_config[0]);
    localStorage.setItem("config", JSON.stringify(current_route_and_config[1]));
    router.replace({ path: "/login_refresh" });
  },

  setGlobalError: ({ commit }, error) => {
    commit("setGlobalError", { error: error, typeofalert: "warning" });
    localStorage.removeItem("username");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_id");
    router.replace("login");
  },

  removeGlobalError: ({ commit }, error) => {
    commit("setGlobalError", { error: error, typeofalert: "warning" });
  },

  register_live: async ({ commit }, survey_id) => {
    localStorage.setItem("token_live", null);
    localStorage.setItem("username_live", null);
    localStorage.setItem("refresh_token_live", null);
    localStorage.setItem("user_id_live", null);
    await axios
      .post("/api/register_live", {
        survey_id: survey_id,
      })
      .then((response) => {
        let success = response.data.success;

        if (success === true) {
          commit("authUser_live", {
            username_live: response.data.username_live,
            token_live: response.data.token_live,
            refresh_token_live: response.data.refresh_token_live,
            user_id_live: response.data.user_id,
            storage_accepted: true,
          });
          localStorage.setItem("token_live", response.data.token_live);
          localStorage.setItem("username_live", response.data.username_live);
          localStorage.setItem(
            "refresh_token_live",
            response.data.refresh_token_live
          );
          localStorage.setItem("user_id_live", response.data.user_id);
        } else {
          commit("setGlobalError", {
            error: "Invalid credentials",
            typeofalert: "warning",
          });
          commit("clearAuthData_live");
        }
      })
      .catch((e) => {
        console.log("error with register live");
        console.log("error was: ", e);
        localStorage.setItem("token_live", null);
        localStorage.setItem("username_live", null);
        localStorage.setItem("refresh_token_live", null);
        localStorage.setItem("user_id_live", null);
        commit("setGlobalError", {
          error: "Username already exists",
          typeofalert: "warning",
        });
      });
  },

  register_live_list: async ({ commit }, form_data) => {
    localStorage.setItem("token_live", null);
    localStorage.setItem("username_live", null);
    localStorage.setItem("refresh_token_live", null);
    localStorage.setItem("user_id_live", null);
    await axios
      .post("/api/register_live_list", {
        survey_id_list: form_data.survey_id_list,
      })
      .then(async (response) => {
        let success = response.data.success;
        if (success === true) {
          await commit("authUser_live", {
            username_live: response.data.username_live,
            token_live: response.data.token_live,
            refresh_token_live: response.data.refresh_token_live,
            user_id_live: response.data.user_id,
            storage_accepted: true,
          });
          localStorage.setItem("token_live", response.data.token_live);
          localStorage.setItem("username_live", response.data.username_live);
          localStorage.setItem(
            "refresh_token_live",
            response.data.refresh_token_live
          );
          localStorage.setItem("user_id_live", response.data.user_id);
        } else {
          commit("setGlobalError", {
            error: "Invalid credentials",
            typeofalert: "warning",
          });
          commit("clearAuthData_live");
        }
      })
      .catch((e) => {
        console.log("error during register live: ");
        console.log(e);
        localStorage.setItem("token_live", null);
        localStorage.setItem("username_live", null);
        localStorage.setItem("refresh_token_live", null);
        localStorage.setItem("user_id_live", null);
        commit("setGlobalError", {
          error: "Username already exists",
          typeofalert: "warning",
        });
      });
  },

  clearAuthData_live: ({ commit }) => {
    //remove all items from local storage
    localStorage.removeItem("token_live");
    localStorage.removeItem("username_live");
    localStorage.removeItem("refresh_token_live");
    localStorage.removeItem("user_id_live");
    localStorage.removeItem("history");
    localStorage.removeItem("storage_accepted");
    // to make sure that everything is deleted, even in dev settings
    localStorage.removeItem("loglevel:webpack-dev-server");
    commit("clearAuthData_live");
  },

  set_storage_acceptance_status(context, acceptance) {
    context.commit("save_storage_accepted_state", acceptance);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
