import Vue from "vue";
import VueRouter from "vue-router";
import { instance as axiosAuth } from "@/api/axios-auth";
import { instance2 as axiosAuth_live } from "@/api/axios-auth_live";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/login_refresh",
    name: "Login_Refresh",
    component: () => import("../views/Login_Refresh.vue"),
  },
  {
    path: "/",
    name: "Root",
    component: () => import("../views/Dashboard"),
    meta: { requiresAuth: true },
  },
  {
    path: "/configurator",
    name: "Configurator_parent",
    component: () => import("../views/Configurator.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Configurator.vue"),
        name: "configurator",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/assess",
    name: "Assess_parent",
    component: () => import("../views/Assess.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Assess.vue"),
        name: "Assess",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/assess_live",
    name: "Assess_live_parent",
    component: () => import("../views/Assess.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Assess.vue"),
        name: "Assess_live",
      },
    ],
    meta: { requiresAuth: false },
  },
  {
    path: "/dialog",
    name: "Dialog_parent",
    component: () => import("../views/Assess.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Assess.vue"),
        name: "Dialog",
      },
    ],
    meta: { requiresAuth: false },
  },

  {
    path: "/multi",
    name: "Multichat_parent",
    component: () => import("../views/Assess_Multichat.vue"),
    children: [
      {
        path: ":manager_id",
        component: () => import("../views/Assess_Multichat.vue"),
        name: "Multichat",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/multi_live",
    name: "Multichat_live_parent",
    component: () => import("../views/Assess_Multichat.vue"),
    children: [
      {
        path: ":manager_id",
        component: () => import("../views/Assess_Multichat.vue"),
        name: "Multichat_live",
      },
    ],
    meta: { requiresAuth: false },
  },
  // extra route for tatort game 1
  {
    path: "/flow",
    name: "Multichat_live_flow",
    component: () => import("../views/Assess_Multichat.vue"),
    meta: {
      requiresAuth: false,
      manager_id: 3,
      title: "Tatort-Game",
      description:
        "Ermittle im Tatort Game gemeinsam mit den Kommissaren. Löse die Rätsel zum Mordfall, sammle Indizien und überführe den Täter",
      favicon: "/tatort.ico",
      metaTags: [
        {
          name: "description",
          content:
            "Das Tatort Game: Schaffst du es, gemeinsam mit den Kommissaren den Mordfall zu lösen?",
        },
        {
          property: "og:title",
          content: "Werde Ermittler*in im Tatort Game und löse den Mordfall.",
        },
        {
          property: "og:description",
          content:
            "Das Tatort Game: Schaffst du es, gemeinsam mit den Kommissaren den Mordfall zu lösen?",
        },
        {
          property: "twitter:title",
          content: "Tatort-Game",
        },
        {
          property: "twitter:description",
          content:
            "Das Tatort Game: Schaffst du es, gemeinsam mit den Kommissaren den Mordfall zu lösen?",
        },
      ],
    },
  },
  // extra route for tatort game 2
  {
    path: "/aufderflucht",
    name: "Multichat_live_flucht",
    component: () => import("../views/Assess_Multichat.vue"),
    meta: {
      requiresAuth: false,
      manager_id: 4,
      title: "Tatort-Game",
      description:
        "Ermittle im Tatort Game gemeinsam mit den Kommissaren. Löse die Rätsel zum Mordfall, sammle Indizien und überführe den Täter",
      favicon: "/tatort.ico",
      metaTags: [
        {
          name: "description",
          content:
            "Das Tatort Game: Schaffst du es, gemeinsam mit den Kommissaren den Mordfall zu lösen?",
        },
        {
          property: "og:title",
          content: "Werde Ermittler*in im Tatort Game und löse den Mordfall.",
        },
        {
          property: "og:description",
          content:
            "Das Tatort Game: Schaffst du es, gemeinsam mit den Kommissaren den Mordfall zu lösen?",
        },
        {
          property: "twitter:title",
          content: "Tatort-Game",
        },
        {
          property: "twitter:description",
          content:
            "Das Tatort Game: Schaffst du es, gemeinsam mit den Kommissaren den Mordfall zu lösen?",
        },
      ],
    },
  },

  {
    path: "/exit",
    name: "Assess_live_exit_parent",
    component: () => import("../views/Exit.vue"),
    meta: { requiresAuth: false },
    children: [
      {
        path: ":var",
        component: () => import("../views/Exit.vue"),
        name: "Dialog_exit",
      },
    ],
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/impressum_tatort",
    name: "Impressum_SWR",
    component: () => import("../views/Impressum_Tatort.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/bi",
    name: "bi",
    component: () => import("../views/bi.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import("../views/Datenschutz.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/agb",
    name: "AGB",
    component: () => import("../views/agb.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/privacy",
    name: "Privacy_parent",
    component: () => import("../views/Privacy.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Privacy.vue"),
        name: "Privacy",
      },
    ],
    meta: { requiresAuth: false },
  },
  {
    path: "/magic_link",
    name: "Magic_link",
    component: () => import("../views/Login_magic_link.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/email_validation",
    name: "E-Mail_Validation",
    component: () => import("../views/Email_validation.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/reset_password",
    name: "E-Mail_Reset_Password",
    component: () => import("../views/Email_reset_password"),
    meta: { requiresAuth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard"),
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Account"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin"),
    meta: { requiresAuth: true },
  },
  {
    path: "/analyse",
    name: "AnalyseStory_parent",
    component: () => import("../views/Analyse.vue"),
    children: [
      {
        path: "refresh_login",
        component: () => import("../views/Login_Refresh.vue"),
        name: "Analyse_Login_Refresh",
      },
      {
        path: ":survey_id",
        component: () => import("../views/Analyse.vue"),
        name: "Analyse",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/analyse_multichat",
    name: "AnalyseMultiChat_parent",
    component: () => import("../views/Analyse_Multichat.vue"),
    children: [
      {
        path: "refresh_login",
        component: () => import("../views/Login_Refresh.vue"),
        name: "Analyse_Multi_Chat_Login_Refresh",
      },
      {
        path: ":dialog_manager_id",
        component: () => import("../views/Analyse_Multichat.vue"),
        name: "AnalyseMultiChat",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/intrance",
    name: "Intrance_parent",
    component: () => import("../views/Intrance.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Intrance.vue"),
        name: "Intrance",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/intrance_old",
    name: "old_intrance_parent",
    component: () => import("../views/Intrance_old.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Intrance_old.vue"),
        name: "Old_Intrance",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/share",
    name: "Share_parent",
    component: () => import("../views/Share.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../views/Share.vue"),
        name: "Share",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/widget",
    name: "Widget_Only_parent",
    component: () => import("../components/share/Widget_only_screen.vue"),
    children: [
      {
        path: ":survey_id",
        component: () => import("../components/share/Widget_only_screen.vue"),
        name: "Widget_Only",
      },
    ],
    meta: { requiresAuth: false },
  },
  {
    path: "*",
    name: "About",
    component: () => import("../views/Login.vue"),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function meta_stuff(to, from, next) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithDescription = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.description);

  const nearestWithFavicon = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.favicon);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  } else {
    document.title = "Respeak Dialog Experience";
  }

  // If a route with a description was found, set the document (page) description to that value.
  if (nearestWithDescription) {
    document.description = nearestWithTitle.meta.description;
  } else if (previousNearestWithMeta) {
    document.description = previousNearestWithMeta.meta.description;
  } else {
    document.description =
      "Follow this link to get started with your Respeak dialog. We are looking forward to providing you a better learning experience.";
  }

  const link = document.createElement("link");
  link.rel = "icon";
  if (nearestWithFavicon) {
    link.href = nearestWithFavicon.meta.favicon;
  } else {
    link.href = "favicon.ico";
  }

  document.head.appendChild(link);

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  const default_head_tags = [
    {
      name: "title",
      content: "Respeak Dialog Experience",
    },
    {
      name: "description",
      content:
        "Follow this link to get started with your Respeak dialog. We are looking forward to providing you a better learning experience.",
    },
    {
      property: "twitter:title",
      content: "Respeak Dialog Experiences",
    },
    {
      property: "twitter:description",
      content:
        "Follow this link to get started with your Respeak dialog. We are looking forward to providing you a better learning experience.",
    },
    {
      property: "og:title",
      content: "Respeak Dialog Experiences",
    },
    {
      property: "og:description",
      content:
        "Follow this link to get started with your Respeak dialog. We are looking forward to providing you a better learning experience.",
    },
  ];

  // add default head tags if not set in meta
  default_head_tags.forEach((tag) => {
    if (
      !nearestWithMeta.meta.metaTags.find(
        (t) =>
          (t.name && t.name === tag.name) ||
          (t.property && t.property === tag.property)
      )
    ) {
      nearestWithMeta.meta.metaTags.push(tag);
    }
  });

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
}

router.beforeEach((to, from, next) => {
  meta_stuff(to, from, next);
  //check that store is always filled with meta information
  let check_store_routes = [
    "/configurator",
    "/intrance",
    "/intrance_ai",
    "/assess",
    "/share",
    "/analyse",
    "/account",
  ];
  if (check_store_routes.indexOf(to.matched[0].path) > -1) {
    //is route that requires store
    // check if survey info in store needs updating
    if (
      to.params.survey_id != undefined &&
      (store.state.survey_data == null ||
        store.state.survey_data.survey_id != to.params.survey_id)
    ) {
      // update if necessary
      //refresh survey element on frontend
      axiosAuth
        .post(`/api/dialog`, {
          survey_id: to.params.survey_id,
        })
        .then((response) => {
          // store in global store for navigation components to access
          store.dispatch("updateSurveyData", response.data);
        });
      // .catch((error) => {
      //   store.dispatch("error_alert", error);
      // });
    } else {
      // no update necessary do nothing
    }
  }

  let token = localStorage.getItem("token");
  let requireAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (to.matched[0].path === "/login_refresh") {
    if (localStorage.getItem("login_refresh_route") == null) {
      store.dispatch("auth/setGlobalError", "Bitte anmelden.");
    } else {
      next();
    }
  } else if (to.matched[0].path === "/magic_link") {
    axiosAuth_live
      .post(`/api/magic_link`, { magic_link: to.query.token })
      .then(() => {
        next();
      })
      .catch(() => {
        store.dispatch(
          "auth/setGlobalError",
          "Ungültiger Link. Bitte erneut versuchen oder anmelden."
        );
      });
  } else if (to.path === "/login") {
    if (token) {
      axiosAuth
        .post("/api/verify-token")
        .then(() => {
          next("/dashboard");
        })
        .catch(() => {
          next();
        });
    } else {
      next();
    }
  } else if (!requireAuth) {
    next();
  } else if (requireAuth && !token) {
    next("/login");
  } else if (requireAuth && token) {
    axiosAuth
      .post("/api/verify-token")
      .then(() => {
        next();
      })
      .catch(() => {
        store.dispatch(
          "auth/setGlobalError",
          "Sie wurden abgemeldet. Bitte erneut anmelden."
        );
      });
  }
});

export default router;
