import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";

import "../node_modules/material-design-icons-iconfont/dist/material-design-icons.css";

import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

new Vue({
  router,
  store,
  vuetify,
  VueMeta,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
