import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(
      _vm.$route.name != 'Privacy' &&
      _vm.$route.name != 'Login' &&
      _vm.$route.name != 'Datenschutz' &&
      _vm.$route.name != 'E-Mail_Validation' &&
      _vm.$route.name != 'E-Mail_Reset_Password' &&
      _vm.$route.name != 'Magic_link' &&
      _vm.$route.name != 'Login_Refresh' &&
      _vm.$route.name != 'Widget_Only' &&
      _vm.$route.name != 'About'
    )?_c('NavBar'):_vm._e(),_c(VMain,{staticStyle:{"background":"#fbfbfb"}},[_c(VContainer,{class:[
        { mobile: _vm.$vuetify.breakpoint.xs },
        {
          'pa-0':
            _vm.$route.name == 'Login' ||
            _vm.$route.name == 'E-Mail_Validation' ||
            _vm.$route.name == 'Magic_link' ||
            _vm.$route.name == 'About' ||
            _vm.$route.name == 'Assess' ||
            _vm.$route.name == 'Assess_live' ||
            _vm.$route.name == 'Dialog' ||
            (_vm.$route.name && _vm.$route.name.includes('Multichat')),
        },
      ],attrs:{"fluid":""}},[_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c('router-view')],1)],1)],1),_c('error_dialog',{attrs:{"error_show":_vm.error_handler.show,"error_title":_vm.error_handler.title,"error_msg":_vm.error_handler.msg}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }