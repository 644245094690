<template>
  <v-card width="370" max-height="70vh">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle v-if="sharing_permission == 1"
          >Lade deine Teammitglieder zu deinem Dialog ein
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else
          >Teammitglieder bei diesem Dialog
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <div v-if="loading_permissions == true">
      <!--        LOADING-->
      <v-card-text>
        <div class="d-flex justify-center">
          <v-progress-circular
            width="3"
            size="50"
            color="info"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-card-text>
    </div>

    <div v-else class="mt-2">
      <!-- invite with email and submit button -->
      <v-row class="px-4" v-if="sharing_permission == 1">
        <v-col>
          <v-form ref="emailform" v-model="isFormValid">
            <v-text-field
              v-model="invite_email"
              :rules="emailRules"
              dense
              required
              color="info"
              @keydown.enter.prevent="validate_reset_password"
              style="font-size: 12px"
            >
              <template v-slot:label>
                <span style="font-size: 12px">Email angeben</span>
              </template>
              <template v-slot:append>
                <v-select
                  v-model="invite_permission"
                  :items="permissions"
                  solo
                  flat
                  single-line
                  hide-details
                  full-width
                  dense
                  class="pl-0 rights_input"
                  ><template v-slot:label>
                    <span style="font-size: 12px">Berechtigung</span>
                  </template></v-select
                >
              </template>
              <template v-slot:append-outer>
                <v-btn
                  x-small
                  outlined
                  :disabled="!validate_invite_email"
                  color="success"
                  @click="invite"
                  class="mt-2"
                >
                  Einladen
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <!-- v list of invited people with two columns : info and edit rights -->
      <v-divider class="mb-3"></v-divider>
      <span class="v-list-item__subtitle px-4" style="color: rgba(0, 0, 0, 0.6)"
        >Freigaben</span
      >
      <v-list dense class="px-4 overflow-y-auto">
        <v-list-item
          v-for="item in roles_current_survey_id"
          :key="item.name"
          :three-line="item.role != 'Besitzer' && item.invite_accepted == 0"
          dense
          class="px-0"
        >
          <v-list-item-content>
            <v-list-item-title style="font-size: 14px">
              {{ item.name }}
            </v-list-item-title>
            <template
              v-if="item.role != 'Besitzer' && item.invite_accepted == 0"
            >
              <v-list-item-subtitle
                ><span style="font-size: 12px"
                  >Einladung noch nicht angenommen
                </span></v-list-item-subtitle
              >
              <v-list-item-subtitle
                ><a
                  @click="resend(item.name, item.role)"
                  style="font-size: 12px"
                  >Nochmal senden</a
                ></v-list-item-subtitle
              >
            </template>
          </v-list-item-content>

          <span v-if="sharing_permission != 1" class="infoText">
            {{ item.role }}</span
          >

          <v-list-item-action class="my-0" v-if="sharing_permission == 1">
            <v-select
              v-if="item.invite_accepted == 1"
              v-model="item.role"
              :items="permissions"
              :name="item.role"
              label="Berechtigung"
              solo
              flat
              single-line
              hide-details
              full-width
              dense
              class="pl-0 rights_input"
              @change="changed_permission($event, item.name)"
            >
              <template v-slot:append-outer>
                <v-btn
                  v-if="sharing_permission == 1"
                  small
                  icon
                  @click="delete_permission(item.name)"
                  class="mt-n1"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-select>
            <!-- is owner and current account -->
            <div v-else>
              <span v-if="item.invite_accepted == null" class="infoText">
                Besitzer</span
              >
              <v-btn
                v-else
                small
                icon
                @click="delete_permission(item.name)"
                class="mt-6"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script>
import { instance as axiosAuth } from "@/api/axios-auth";

export default {
  name: "SharingDialog",

  props: {
    survey_id: {
      type: [Number, String],
    },
  },

  components: {},

  data() {
    return {
      permissions: [
        "Voller Zugriff",
        "Bearbeiten + Trainieren",
        "Trainieren",
        "Analysieren",
      ],
      loading_permissions: true,
      roles_current_survey_id: [],
      selected_permission: "asd",
      invite_email: "",
      sharing_permission: false,
      isFormValid: false,
      invite_permission: "",
      emailRules: [
        (v) => !!v || "Bitte gültige E-Mail angeben",
        (v) => /.+@.+\..+/.test(v) || "Bitte gültige Email angeben",
      ],
    };
  },

  computed: {
    validate_invite_email() {
      if (
        this.invite_email != "" &&
        this.isFormValid == true &&
        this.invite_permission != ""
      ) {
        return true;
      }
      return false;
    },
  },

  created() {
    this.get_dialog_permissions(true);
    this.invite_email = "";
    this.invite_permission = "";
  },

  methods: {
    close() {
      this.$emit("close_dialog");
    },

    async resend(item_name, role) {
      await axiosAuth
        .post(`/api/resend_invite`, {
          survey_id: this.survey_id,
          name: item_name.trim(),
          new_role: role,
        })
        .then(() => {
          //if success change switch
          this.get_dialog_permissions(false);
          this.invite_email = "";
          this.invite_permission = "";
        })
        .catch((error) => {
          this.$store.dispatch("error_alert", error);
          // this.loading_permissions = false;
        });
    },

    async invite() {
      if (this.$refs.emailform.validate()) {
        this.loading_permissions = true;
        await axiosAuth
          .post(`/api/invite_user`, {
            survey_id: this.survey_id,
            name: this.invite_email.trim(),
            new_role: this.invite_permission,
          })
          .then(() => {
            //if success change switch
            this.get_dialog_permissions(true);
            this.invite_email = "";
            this.invite_permission = "";
          })
          .catch((error) => {
            this.$store.dispatch("error_alert", error);
            // this.loading_permissions = false;
          });
      }
    },

    async changed_permission(event, item_name) {
      // this.loading_permissions = true;
      await axiosAuth
        .post(`/api/change_permission_user`, {
          survey_id: this.survey_id,
          name: item_name,
          new_role: event,
        })
        .then(() => {
          //if success change switch
          this.get_dialog_permissions(false);
        })
        .catch((error) => {
          this.$store.dispatch("error_alert", error);
          // this.loading_permissions = false;
        });
    },

    async delete_permission(item_name) {
      this.loading_permissions = true;
      await axiosAuth
        .post(`/api/delete_sharing_user`, {
          survey_id: this.survey_id,
          name: item_name,
        })
        .then(() => {
          //if success change switch
          this.get_dialog_permissions(false);
        })
        .catch((error) => {
          this.$store.dispatch("error_alert", error);
          this.loading_permissions = false;
        });
    },

    async get_dialog_permissions(reload) {
      if (reload == true) {
        this.loading_permissions = true;
      }
      if (this.survey_id == null) {
        this.close();
      } else {
        await axiosAuth
          .post(`/api/get_dialog_permissions`, {
            survey_id: this.survey_id,
          })
          .then((response) => {
            //if success change switch
            this.loading_permissions = false;
            this.roles_current_survey_id =
              response.data.roles_current_survey_id;
            this.sharing_permission = response.data.sharing_permission;
          })
          .catch((error) => {
            this.$store.dispatch("error_alert", error);
            this.loading_permissions = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.rights_input {
  font-size: 12px;
  max-width: 175px;
  display: flex;
  align-items: center;
}

.infoText {
  font-size: 12px;
}
</style>
