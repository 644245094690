<template>
  <v-app>
    <NavBar
      v-if="
        $route.name != 'Privacy' &&
        $route.name != 'Login' &&
        $route.name != 'Datenschutz' &&
        $route.name != 'E-Mail_Validation' &&
        $route.name != 'E-Mail_Reset_Password' &&
        $route.name != 'Magic_link' &&
        $route.name != 'Login_Refresh' &&
        $route.name != 'Widget_Only' &&
        $route.name != 'About'
      "
    />
    <v-main style="background: #fbfbfb">
      <v-container
        fluid
        :class="[
          { mobile: $vuetify.breakpoint.xs },
          {
            'pa-0':
              $route.name == 'Login' ||
              $route.name == 'E-Mail_Validation' ||
              $route.name == 'Magic_link' ||
              $route.name == 'About' ||
              $route.name == 'Assess' ||
              $route.name == 'Assess_live' ||
              $route.name == 'Dialog' ||
              ($route.name && $route.name.includes('Multichat')),
          },
        ]"
      >
        <transition name="component-fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
    <!-- hide footer on small viewports or when softkeyboard (which reduces viewport height) is visible -->
    <!-- <Footer
      v-if="
        $vuetify.breakpoint.height > 600 &&
        this.$router.currentRoute.name != 'Analyse'
      "
    /> -->
    <!-- global error handling -->
    <error_dialog
      :error_show="error_handler.show"
      :error_title="error_handler.title"
      :error_msg="error_handler.msg"
    ></error_dialog>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import error_dialog from "@/components/error_dialog";

export default {
  name: "RespeakApp",

  components: {
    NavBar,
    error_dialog,
  },

  data: () => ({
    //
  }),

  created() {
    // automatically login users with relevant login information in store
    this.$store.dispatch("auth/autoLogin");
    // for reloading live dialog users, populate store with relevant info
    this.$store.dispatch("auth/autoLoginLive");
  },

  computed: {
    error_handler() {
      return this.$store.state.error_handler;
    },
  },
};
</script>

<style>
/*respeak font*/
@import url("https://fonts.googleapis.com/css?family=Lexend+Zetta");

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.2s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}

.v-dialog.dialogStyle {
  box-shadow: none;
  border: none;
}

.v-dialog.highestDialog {
  z-index: 42;
}

.app_nav_menu {
  box-shadow: none;
  border: 1px solid lightgray;
  border-radius: 0px;
}

div > .v-application--wrap {
  min-height: 100%;
}

body {
  background: #fbfbfb;
  overflow-y: scroll;
  height: 100svh;
}

html {
  overflow-y: hidden;
}
</style>
