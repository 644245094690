import store from "@/store";
import { instance as axiosAuth } from "@/api/axios-auth";

// core function for media file retreival
function return_media_url_misc(survey_id, filename, get_misc_data = false) {
  // remove "file: " beginning of name if present in filename
  if (filename.startsWith("file:")) {
    filename = filename.substring(5).trim();
  }
  // lookup file location for requested file in store
  const media_file_obj = store.state.media_files.find(
    (file) => file.survey_id === survey_id && file.filename_db === filename
  );
  if (media_file_obj !== undefined) {
    // file found in store, return url
    return get_misc_data
      ? [media_file_obj.location, media_file_obj.misc]
      : media_file_obj.location;
  }
  // no file found
  else {
    let media_url = "";
    let media_misc = {};
    const images = require.context("../assets/", false, /\.png$|\.jpg$/);
    // default value for avatar or logo
    if (filename == "logo") {
      media_url = images("./RS_Banner.png");
    } else if (filename == "avatar") {
      media_url = images("./Person_TRI2_smallSize.jpg");
    } else {
      media_url = "";
    }
    // fallback for files that are requested with filename = filetype, such as logo or avatar
    const media_file_obj_logoavatar = store.state.media_files.find(
      (file) => file.survey_id == survey_id && file.type == filename
    );
    if (media_file_obj_logoavatar != undefined) {
      media_url = media_file_obj_logoavatar.location;
      media_misc = media_file_obj_logoavatar.misc;
    }
    return get_misc_data ? [media_url, media_misc] : media_url;
  }
}

// function for components to call to get media url
function get_media_url(survey_id, filename, get_misc_data = false) {
  return return_media_url_misc(survey_id, filename, get_misc_data);
}

// function for components to call to get media url
async function get_media_list(survey_id) {
  await axiosAuth
    .post(`/api/get_media_list`, {
      survey_id: survey_id,
    })
    .then((response) => {
      // save then in store as well for easy access
      store.dispatch("update_media_files", response.data.media_files.reverse());
    })
    .catch((error) => {
      store.dispatch("error_alert", error);
    });
}

// completes the dm_states object with local history. if no local history is available, initialize history
function initialize_history_from_local_storage(states, survey_id_list) {
  // get current history from local storage
  let history = localStorage.getItem("history");
  // if no history exists, initialize and return dm_states as is
  if (history == null || !all_ids_in_existing_history(states, history)) {
    create_new_history_entry(states, history);
    return states;
  }
  // else fill states with history
  else {
    // adjust history to reset question process based on backend flag
    if (states[0].state.retrieve_partial_history) {
      // if flag is set for one state, reset for all states
      history = reset_history_to_main_question(survey_id_list);
    }

    states.forEach((dialog) => {
      // create a new key dialog_history in each dialog
      dialog.dialog_history = {};
      // create a flag that tells messengerUI that this is a returning user
      dialog.state.returning_user = true;
      // fill dialog_history with history from local storage
      Object.assign(dialog.dialog_history, {
        dialog_log: JSON.parse(history)[dialog.state.current_survey_id],
      });
    });
    return states;
  }
}

// check if one of the keys in states is not in history yet. returns false of a key is missing, else true
function all_ids_in_existing_history(states, history) {
  if (history == null || history == "null") {
    return false;
  }
  history = JSON.parse(history);
  const surveyIds = new Set(Object.keys(history));
  for (const dialog of states) {
    if (!surveyIds.has(String(dialog.state.current_survey_id))) {
      return false;
    }
  }
  return true;
}

// delete all history for current question id where nachfrage_id > 1. active if retrieve_partial_history is true
function reset_history_to_main_question(survey_id_list) {
  let current_history = localStorage.getItem("history");
  if (current_history) {
    current_history = JSON.parse(current_history);
  }
  // loop over the entries of current history where they key is in survey_id_list (array of survey_ids as int, not as string)
  // for each entry, which is a history for a specific dialog, go over the array of included objects
  // for the array of objects, the first entry.current_question_id that is not in the array entry.finished_question_id, and delete all dialog.entries for the dialog history where nachfrage_id than higher than 0

  for (const key in current_history) {
    if (survey_id_list.includes(parseInt(key))) {
      // get the array of objects for this dialog
      const dialog_history = current_history[key];
      // get the current question id for this dialog
      const current_question_id =
        dialog_history[dialog_history.length - 1].current_question_id;
      // get the finished question ids for this dialog
      const finished_question_ids =
        dialog_history[dialog_history.length - 1].finished_question_id;
      // check if the current question id is in the finished question ids array: if yes, all questions are finished, so no need to delete anything
      const index = finished_question_ids.findIndex(
        (id) => id == current_question_id
      );
      // if index is -1, the current question id is not in the finished question ids array, so we need to delete all entries where nachfrage_id > 0
      if (index == -1) {
        // loop over the dialog_history array and delete all entries where nachfrage_id > 0 and current_question_id == current_question_id
        for (let i = dialog_history.length - 1; i >= 0; i--) {
          if (
            dialog_history[i].nachfrage_id > 0 &&
            dialog_history[i].current_question_id == current_question_id
          ) {
            dialog_history.splice(i, 1);
          }
        }
      }
    }
  }
  // set adjusted history
  localStorage.setItem("history", JSON.stringify(current_history));
  return JSON.stringify(current_history);
}

// updates local history with latest entries from dm_states
function update_local_storage_history(states) {
  create_new_history_entry(states, localStorage.getItem("history"));
}

// function that creates a new history entry from states
// history is json of shape: survey_id: [{ context, created_on, type, unique_question_id, user_or_bot }]
function create_new_history_entry(states, existing_history = {}) {
  if (existing_history !== null && existing_history !== "null") {
    existing_history = JSON.parse(existing_history);
  } else {
    existing_history = {};
  }
  let history = {};
  // first initalization of history
  if (Object.keys(existing_history).length == 0) {
    let initial_history = {};
    for (let survey of states) {
      // initialize key
      Object.assign(initial_history, {
        [survey.state.current_survey_id]: [],
      });
      let bot_message_in_state = survey.state.chatbot_response_object.context;
      // add welcome message if available
      if (
        "welcome_message" in survey.state &&
        survey.state.welcome_message?.context != ""
      ) {
        initial_history[survey.state.current_survey_id].push({
          context: survey.state.welcome_message.context,
          created_on: survey.state.current_time
            ? survey.state.current_time * 1000
            : Date.now(),
          type: survey.state.welcome_message.type,
          unique_question_id: survey.state.welcome_message.unique_question_id,
          nachfrage_id: survey.state.nachfrage_id,
          current_question_id: survey.state.current_question_id,
          finished_question_id: survey.state.finished_question_id,
          user_or_bot: "bot",
        });
      }
      // add first bot message
      initial_history[survey.state.current_survey_id].push({
        context: bot_message_in_state,
        created_on: survey.state.current_time
          ? survey.state.current_time * 1000
          : Date.now(),
        type: survey.state.chatbot_response_object.type,
        unique_question_id:
          survey.state.chatbot_response_object.unique_question_id,
        nachfrage_id: survey.state.nachfrage_id,
        current_question_id: survey.state.current_question_id,
        finished_question_id: survey.state.finished_question_id,
        user_or_bot: "bot",
      });
    }
    history = initial_history;
  }
  // expand existing history
  else {
    for (let survey of states) {
      let bot_message_in_state = survey.state.chatbot_response_object.context;
      // check if existing_history already has a key survey.state.current_survey_id
      if (survey.state.current_survey_id in existing_history) {
        // if the changes are not contained in history already, add them
        if (untracked_changes(existing_history, survey)) {
          // add two or three new entries to the history array:
          // (one for acknowledgement or welcome message if available), one for bot message and one for user message
          if (survey.state.should_be_display) {
            // ? latest user response, only if user input should be displayed to account for trigger_solutions in multichat
            existing_history[survey.state.current_survey_id].push({
              context: survey.state.user_input,
              created_on: survey.state.current_time * 1000 - 10, // manual offset to adequately display user input time before bot message time
              type: survey.state.chatbot_response_object.type + "-r",
              unique_question_id:
                survey.state.chatbot_response_object.unique_question_id,
              nachfrage_id: survey.state.nachfrage_id,
              current_question_id: survey.state.current_question_id,
              finished_question_id: survey.state.finished_question_id,
              user_or_bot: "user",
            });
          }
          // acknowledgement or welcome message
          if (
            "welcome_message" in survey.state &&
            survey.state.welcome_message?.context != ""
          ) {
            existing_history[survey.state.current_survey_id].push({
              context: survey.state.welcome_message.context,
              created_on: survey.state.current_time * 1000,
              type: survey.state.welcome_message.type,
              unique_question_id:
                survey.state.welcome_message.unique_question_id,
              nachfrage_id: survey.state.nachfrage_id,
              current_question_id: survey.state.current_question_id,
              finished_question_id: survey.state.finished_question_id,
              user_or_bot: "bot",
            });
          }
          // latest bot response
          existing_history[survey.state.current_survey_id].push({
            context: bot_message_in_state,
            created_on: survey.current_time * 1000,
            type: survey.state.chatbot_response_object.type,
            unique_question_id:
              survey.state.chatbot_response_object.unique_question_id,
            nachfrage_id: survey.state.nachfrage_id,
            current_question_id: survey.state.current_question_id,
            finished_question_id: survey.state.finished_question_id,
            user_or_bot: "bot",
          });
        }
      }
      // create initial entry for new key
      else {
        // initialize key
        Object.assign(existing_history, {
          [survey.state.current_survey_id]: [],
        });
        // triggering user input if user input is not empty
        if (survey.state.user_input != "") {
          existing_history[survey.state.current_survey_id].push({
            context: survey.state.user_input,
            created_on: survey.state.current_time * 1000 - 10, // manual offset to adequately display user input time before bot message time
            type: "Open-r",
            unique_question_id:
              survey.state.chatbot_response_object.unique_question_id,
            nachfrage_id: survey.state.nachfrage_id,
            current_question_id: survey.state.current_question_id,
            finished_question_id: survey.state.finished_question_id,
            user_or_bot: "user",
          });
        }
        // add bot message
        existing_history[survey.state.current_survey_id].push({
          context: bot_message_in_state,
          created_on: survey.state.current_time * 1000,
          type: survey.state.chatbot_response_object.type,
          unique_question_id:
            survey.state.chatbot_response_object.unique_question_id,
          nachfrage_id: survey.state.nachfrage_id,
          current_question_id: survey.state.current_question_id,
          finished_question_id: survey.state.finished_question_id,
          user_or_bot: "bot",
        });
      }
    }
    history = existing_history;
  }
  localStorage.setItem("history", JSON.stringify(history));
}

// log only one specific answer to local storage (local or bot)
function update_local_storage_history_with_singleanswer(
  state,
  which_sender_to_log
) {
  let existing_history = localStorage.getItem("history");
  existing_history = JSON.parse(existing_history);
  console.log(
    "update_local_storage_history_with_singleanswer",
    " - sender: ",
    which_sender_to_log
  );

  if (which_sender_to_log == "bot") {
    // add bot message
    existing_history[state.current_survey_id].push({
      context: state.chatbot_response_object.context,
      created_on: state.current_time * 1000,
      type: state.chatbot_response_object.type,
      unique_question_id: state.chatbot_response_object.unique_question_id,
      nachfrage_id: state.nachfrage_id,
      current_question_id: state.current_question_id,
      finished_question_id: state.finished_question_id,
      user_or_bot: "bot",
    });
  } else {
    // user
    if (state.user_input != "") {
      existing_history[state.current_survey_id].push({
        context: state.user_input,
        created_on: state.current_time * 1000 - 10, // manual offset to adequately display user input time before bot message time
        type: "Open-r",
        unique_question_id: state.chatbot_response_object.unique_question_id,
        nachfrage_id: state.nachfrage_id,
        current_question_id: state.current_question_id,
        finished_question_id: state.finished_question_id,
        user_or_bot: "user",
      });
    }
  }
  localStorage.setItem("history", JSON.stringify(existing_history));
}

// function that checks if for this particular survey, a new entry should be added to the existing history
function untracked_changes(existing_history, survey) {
  // regular chat case: no new bot message flag, also no multiple chats at the same time, so we always track new changes
  if (survey.new_bot_message === undefined) {
    return true;
  }
  // multichat case
  else {
    return survey.new_bot_message;
  }
}

function reset_history_for_surveyIds(survey_id_list) {
  let current_history = localStorage.getItem("history");
  const keySet = new Set(survey_id_list.map(String));
  if (current_history) {
    current_history = JSON.parse(current_history);
  }
  // remove all keys from history that are in survey_id_list array
  for (const key in current_history) {
    if (keySet.has(key)) {
      delete current_history[key];
    }
  }
  // set adjusted history
  localStorage.setItem("history", JSON.stringify(current_history));
}

export {
  get_media_url,
  initialize_history_from_local_storage,
  update_local_storage_history,
  create_new_history_entry,
  update_local_storage_history_with_singleanswer,
  reset_history_to_main_question,
  reset_history_for_surveyIds,
  get_media_list,
};
