import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.error_title != 'server_busy')?_c(VSnackbar,{staticClass:"mt-6",attrs:{"top":"","text":"","color":"error","elevation":"3","timeout":"-1"},model:{value:(_vm.error_show),callback:function ($$v) {_vm.error_show=$$v},expression:"error_show"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c(VIcon,{attrs:{"left":"","color":"error"}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.message)+" "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.$store.dispatch('change_server_busy_state', false);
          _vm.$router.go(0);}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.dispatch('change_server_busy_state', false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.additional_information != '')?_c('div',[_c(VDivider,{staticClass:"my-3",staticStyle:{"border-color":"#ff00001f"}}),_c('p',{staticClass:"ml-8"},[_vm._v(_vm._s(_vm.additional_information))])],1):_vm._e()]):_c(VSnackbar,{staticClass:"mt-6",attrs:{"top":"","text":"","color":"info","elevation":"3","timeout":"6000"},model:{value:(_vm.error_show),callback:function ($$v) {_vm.error_show=$$v},expression:"error_show"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c(VIcon,{attrs:{"left":"","color":"info"}},[_vm._v("mdi-tortoise")]),_vm._v(" "+_vm._s(_vm.additional_information)+" "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.$store.dispatch('change_server_busy_state', false);
          _vm.$router.go(0);}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.dispatch('change_server_busy_state', false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.additional_information != '')?_c('div',[_c('div',{staticClass:"mt-5 mb-3",staticStyle:{"width":"89%","margin":"auto"}},[_c(VProgressLinear,{attrs:{"color":"info darken-2","stream":"","reverse":"","value":"0","buffer-value":"0"}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }